import { sendOpenSearchLogs } from "utils/helper-functions/send-open-search-logs.ts";

export const getUrlHost = (_url) => {
    try {
        const url = new URL(_url)
        return url.host;
    } catch (e) {
        sendOpenSearchLogs({
            event_group: "cashier_get_url_host",
            error_data: e
        });

        return '';
    }
}