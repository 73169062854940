const setEnableSessionEvents = (state, action) => {
  if (state === undefined) {
    return false;
  }
  switch (action.type) {
    case "SET_ENABLE_SESSION_EVENTS":
      return action.payload;
    default:
      return state.enableSessionEvents;
  }
};

export { setEnableSessionEvents };
