import store from "../../state/store";
import { sendOpenSearchLogs } from "utils/helper-functions/send-open-search-logs.ts";

// Function to sanitize specified fields in a payload object based on non-Latin character presence
export const sanitizeNonLatinCharacters = (payload, fieldsToSanitize) => {
    // Iterate over each field to sanitize as specified by the caller
    fieldsToSanitize.forEach(field => {
        // Ensure the field exists and is an object before proceeding
        if (typeof payload[field] === "object" && payload[field] !== null) {
            // Define a recursive function to deeply sanitize objects
            try {
                const sanitizeObject = (obj) => {
                    Object.entries(obj).forEach(([key, value]) => {
                        if (typeof value === 'object' && value !== null) {
                            sanitizeObject(value); // If value is an object, recurse into it
                        } else if (containsNonLatinLetters(value)) {
                            obj[key] = ""; // Sanitize the value by replacing it with an empty string
                        }
                    });
                };
                sanitizeObject(payload[field]); // Start sanitization on the current field
            }catch (e) {
                const error = {
                    method: `sanitizeNonLatinCharacters - ${field}`,
                    file: "sanitize-fields.js"
                };

                sendOpenSearchLogs({
                    event_group: "cashier_sanitize_non_latin_characters",
                    error_data: {
                        error: {
                            message: e.message,
                            stack: error
                        }
                    }
                });

                console.error(e.message, error);
            }
        } else {
            const errorText = `Validation Error: Payload.${field} must be a non-null object.`;
            const error = {
                method: `sanitizeNonLatinCharacters - ${field}`,
                file: "sanitize-fields.js"
            }

            // Log an error if the specified field is not an object or is null
            console.error(errorText, error);
        }
    });
    
    return payload; // Return the modified payload
};

// Function to check for non-Latin characters in a string
export const containsNonLatinLetters = (str) => {
    // Retrieve the application setting for restricting non-Latin characters
    const { initTheme: { restrict_non_latin_characters } } = store.getState();
    const nonLatinRegex = /[^\u0000-\u007F]+/; // Regex to match non-Latin characters
    
    // Return true if restriction is enabled and the string contains non-Latin characters
    return restrict_non_latin_characters ? nonLatinRegex.test(str) : false;
};