import { isTest } from "../../variables/host";
import store from "state/store";
import { getDeviceData } from "utils/helper-functions/get-device-data.js";
import { getUrl } from "../../variables/host.js";

type Props = {
  level?: string;
  message?: string;
  event_group: string;
  event_name?: string;
  event_context?: any;
  event_data?: any;
  error_data?: any;
};

export const sendOpenSearchLogs = (props: Props) => {
  const isError = props.error_data;
  const {
    token = "",
    sessionId,
    enableSessionEvents,
    profileInfo,
  } = store.getState();

  const deviceData = getDeviceData();

  if (enableSessionEvents === 0) return;

  const getErrorEventGroup = (data: any) => {
    return data?.event_group || "cashier_unknown_event_group";
  };

  const {
    level = isError ? "ERROR" : "INFO",
    event_name = isError ? "cashier_error" : "",
    event_group = isError ? getErrorEventGroup(props.error_data) : "",
    event_context,
    event_data = {},
    error_data,
  } = props;

  const errorMessage =
    error_data?.error?.error_details?.message ||
    error_data?.error?.message ||
    error_data?.error ||
    error_data;

  const errorStack =
    error_data?.error?.error_details?.stack ||
    error_data?.error?.stack ||
    error_data?.stack;

  const headers = {
    type: "application/json",
  };

  const mapComputeDataForLogs = (event_data) => {
    const { 
      cashier_theme, 
      cashier_js,
      cashier_css,
      balance,
      lang,
      locale,
      css,
      communication_token,
      encrypt_cashier_css_class_names,
      enable_websockets,
      enable_cashier_events,
      powerslide_autoredirect_time,
      currency_sign_amount,
      session_duration,
      session_theme,
      validate_domain,
      valid_domains,
      variable1,
      variable2,
      variable3,
      cid,
      amount,
      currency,
      gateway,
      payment_method,
      order_id,
      new_window_size
    } = event_data;

    return {
      cashier_theme, 
      cashier_js,
      cashier_css,
      balance,
      lang,
      locale,
      css,
      communication_token,
      encrypt_cashier_css_class_names,
      enable_websockets,
      enable_cashier_events,
      powerslide_autoredirect_time,
      currency_sign_amount,
      session_duration,
      session_theme,
      validate_domain,
      valid_domains,
      variable1,
      variable2,
      variable3,
      cid,
      amount,
      currency,
      gateway,
      payment_method,
      order_id,
      new_window_size
    }
  };

  const body = {
    timestamp: Math.round(Date.now() / 1000),
    level,
    event_name,
    ...(event_group && { event_group }),
    event_context: {
      page_session_id: sessionId,
      client_ip: profileInfo?.customer?.ip_address,
      user_agent: deviceData.user_agent,
      language: deviceData.language,
      ...(event_context && { ...event_context }),
      ...(isError && {
        error_message: errorMessage,
        error_stack: errorStack
      })
    },
    event_data: event_name === 'application_configurations' ? mapComputeDataForLogs(event_data) : event_data,
  };

  const blob = new Blob([JSON.stringify(body)], headers);

  const url = `${getUrl('logsApi')}/api/v1/logs?auth_token=${token}`;

  if (typeof window.navigator.sendBeacon === "function") {
    if (isTest) {
      console.log("https-request", url, body);
      return;
    }

    let timeoutId;

    const timeoutPromise = new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => {
        reject(new Error("Timeout exceeded"));
      }, 5000);
    });

    const sendBeaconPromise = new Promise((resolve, reject) => {
      const result = window.navigator.sendBeacon(url, blob);
      if (result) {
        resolve(result);
      } else {
        reject(); // Provide new Error("sendBeacon failed") as an argument for debug
      }
    });

    // No point in having .then/.catch unless we decide to log "sendBeacon timeout exceeded" info
    return Promise.race([sendBeaconPromise, timeoutPromise]).finally(() => {
      clearTimeout(timeoutId);
    });
  } else {
    if (body.event_name === 'payment_method_selected') {
      delete body.event_data?.additional_fields;
    }

    const encodeImageUrl = (params) => {
      const urlWithParameters = new URL(url);
      const utf8Bytes = new TextEncoder().encode(JSON.stringify(params));
      const latin1String = String.fromCharCode.apply(null, utf8Bytes);
      urlWithParameters.searchParams.append("payload", btoa(latin1String));

      return urlWithParameters;
    };

    function handleImageRequestError() {
      const params = new URLSearchParams(this.src);
      const payload = JSON.parse(atob(params.get('payload')));

      const existingTemplateObject = payload?.event_data?.templates;

      if (!existingTemplateObject) return;

      const error = this.dataset;

      if (Object.keys(error).length) {
        delete payload.event_data?.templates;
        this.src = encodeImageUrl(payload).href;
      } else {
        const templatesArray = Object.entries(existingTemplateObject);
        const slicedTemplatesArray = templatesArray.slice(0, 15);
        const slicedTemplates = Object.fromEntries(slicedTemplatesArray);

        this.setAttribute('data-error', 1);
        this.src = encodeImageUrl({ ...payload, event_data: { ...payload.event_data, templates: slicedTemplates }}).href;
      }
    };

    const img = document.createElement("img");
    const urlWithParameters = new URL(url);
    const utf8Bytes = new TextEncoder().encode(JSON.stringify(body));
    const latin1String = String.fromCharCode.apply(null, utf8Bytes);
    urlWithParameters.searchParams.append("payload", btoa(latin1String));

    if (isTest) {
      console.log("https-request", urlWithParameters.href);
      return;
    }

    img.setAttribute("style", "opacity:0; position:absolute; z-index:-1;");
    img.width = 0;
    img.height = 0;
    img.src = encodeImageUrl(body).href;

    if (body.event_name === 'payment_method_selected') {
      img.onerror = handleImageRequestError;
    }

    document.body.append(img);
  }
};
