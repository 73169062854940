import {isProd} from "./app-variables";

let getDictionary = () => {};

if (!isProd) {
    getDictionary = require('../mock-data/mock-localization').getDictionary;
}

export const prodTheme = {
    cashier_theme:"default-theme",
    locale:"en-GB",
    lang:"en",
    default_translation: getDictionary("en-GB"),
    valid_domains:[],
    validate_domain:false,
    communication_token:"bf5d0967a7125bf5d0967a7125098f6b"
};
