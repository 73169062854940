import store from "state/store";
import { Dispatch } from "redux";
import { saveLocation } from "state/actions";
import { sendOpenSearchLogs } from "utils/helper-functions/send-open-search-logs";
export class RecoverySettingsModel {
  static parseRecoverySettingsString = (recoverySettingsString: string) => {
    let result = {};
    try {
      result = JSON.parse(atob(recoverySettingsString));
      return result;
    } catch (e) {
      sendOpenSearchLogs({
        event_group: "cashier_recovery_settings",
        error_data: {
          error: {
            message: "failed to parse recovery settings",
            stack: e,
          },
        },
      });
      return result;
    }
  };

  static getRecoverySettings = () => {
    const { location } = store.getState();

    return location.recoverySettingsObject || {};
  };

  static updateRecoverySettings = ({
    recoverySettings,
    dispatch,
  }: {
    recoverySettings: any;
    dispatch: Dispatch<any>;
  }) => {
    const existingRecoverySettings = this.getRecoverySettings();
    const existingLocation = store.getState();
    const updatedRecoverySettings = {
      ...existingRecoverySettings,
      ...recoverySettings,
    };
    const updatedRecoverySettiongsString = btoa(
      JSON.stringify(updatedRecoverySettings)
    );

    const updatedLocation = {
      ...existingLocation,
      hash: `#recoverySettings=${updatedRecoverySettiongsString}`,
      recoverySettingsObject: updatedRecoverySettings,
    };

    dispatch(saveLocation(updatedLocation));
  };
}
