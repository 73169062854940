import {createValidationPayError} from "./pay-data-actions";
import {
    createFatalError,
    createValidationProfileError,
} from "./errors-actions";
import {transformSyntheticEvent} from "../../utils/helper-functions/transform-syntetic-event";
import PraxisService from "../../context-components/service";
import {triggerAdvanceCashierEvent} from "../../utils/custom-cashier-events/trigger-advance-cashier-event";
import {
    sanitizeNonLatinCharacters
} from "../../utils/helper-functions/sanitize-non-latin-characters";
import { sendOpenSearchLogs } from 'utils/helper-functions/send-open-search-logs.ts';
import { RecoverySettingsModel } from "utils/models/RecoverySettings.Model.ts";
import {getDeviceData} from "../../utils/helper-functions/get-device-data";

const service = new PraxisService();
const {
    postPayGateway,
    getUser,
    saveData,
    getGateways,
    getGatewayDetails,
    partialApprovedResult,
    manageRetryRequest,
    postErrors,
    getAvailableGateways
} = service;

export const setEnableSessionEvents = (payload) => {
    return {
        type: "SET_ENABLE_SESSION_EVENTS",
        payload,
    };
};

export const createSessionId = (payload) => {
    return {
        type: "SESSION_ID_INIT",
        payload,
    };
};

export const tokenInit = (payload) => {
    return {
        type: "TOKEN_INIT",
        payload,
    };
};

export const createVirtualSettings = (payload) => {
    return {
        type: "VIRTUAL_SETTINGS",
        payload,
    };
};

export const changeInitTheme = (payload) => {
    return {
        type: "INIT_THEME",
        payload,
    };
};

export const updateGateways = (payload) => {
    return {
        type: "UPDATE_GATEWAYS",
        payload,
    };
};

export const updateProcessingGateways = (payload) => {
    return {
        type: "UPDATE_PROCESSING_GATEWAYS",
        payload,
    };
};

export const setPendingTransactions = (payload) => {
    return {
        type: "SET_PENDING_TRANSACTIONS",
        payload,
    };
};

export const createResultOfPay = (payload) => {
    return {
        type: "CREATE_RESULT_OF_PAY",
        payload,
    };
};

export const changeProfile = (payload) => {
    //Sanitize the customer fields based in the restrict_non_latin_characters flag
    payload = sanitizeNonLatinCharacters(payload, ['customer']);
    return {
        type: "UPDATE_PROFILE",
        payload,
    };
};

export const createMainFocus = (payload) => {
    payload = transformSyntheticEvent(payload);
    return {
        type: "MAIN_FOCUS",
        payload,
    };
};

export const updateGatewayDetails = (payload) => {
    //Sanitize the gateway templates based in the restrict_non_latin_characters flag
    payload = sanitizeNonLatinCharacters(payload,  ['templates']);
    return {
        type: "CREATE_CURRENT_GATEWAY",
        payload,
    };
};

export const updateGatewayInfoFromAmountPage = (payload) => {
    return {
        type: 'CREATE_CURRENT_GATEWAY_FROM_AMOUNT_PAGE',
        payload
    }
}

export const createCurrentGateway = (data) => (dispatch) => {
    return new Promise((resolve) => {
        const card_type_name = data.card_type_name ?? null
        getGatewayDetails(data)
            .then(({data}) => {
                if (data.status === 0) { dispatch(updateGatewayDetails({
                    ...data,
                    card_type_name
                }))
                    dispatch(setLimitSubmit(false));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -1) dispatch(createFatalError(data.error_details));

                sendOpenSearchLogs({
                    event_group: "cashier_events",
                    event_name: "payment_method_selected",
                    event_data: data,
                    event_context: {
                        response_code: data.status,
                    }
                })

                resolve(data);
            })
            .catch((res = {}) => {
                sendOpenSearchLogs({
                    event_group: "cashier_get_gateway_details",
                    error_data: res
                })

                const { response = {} } = res;
                const { data = {} } = response;

                if (data.status === 0) {
                    dispatch(updateGatewayDetails(data))
                    dispatch(setLimitSubmit(false));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -1) dispatch(createFatalError(data.error_details));
                if (!data.status) dispatch(createFatalError());
                resolve(data);
            });
    });
};

export const saveLocation = (payload) => {
    return {
        type: "SAVE-LOCATION",
        payload,
    };
};

export const changeBaseAmount = (payload) => {
    return {
        type: "CHANGE_BASE_AMOUNT",
        payload,
    };
};

const cashierGatewayValidator = (data, dispatch) => {
    if (window.cashierGatewayValidator) {
        return window.cashierGatewayValidator(data).then((el) => {
            if (el.status === 0) data.gateways = el.gateways;
            dispatch(updateGateways(data));
            return {data};
        });
    }
    dispatch(updateGateways(data));
    return {data};
};

export const fetchGateways = (ob_decline_recovery = 0, retry_with_apm = 0) => (dispatch, getState) => {
    const {
        profileInfo: { transaction_type, base_amount, base_currency },
    } = getState();
    return getGateways(transaction_type, ob_decline_recovery, retry_with_apm)
        .then((res = {}) => {
            const { data } = res;

            sendOpenSearchLogs({
                event_group: "cashier_initialization",
                event_name: "open_cashier_state",
                event_data: {
                    amount: base_amount,
                    currency: base_currency,
                    available_payment_methods: data.gateways
                }
            })

            if (data.status === 0) return cashierGatewayValidator(data, dispatch);
            if (data.status === 2) dispatch(createResultOfPay(data));
            if (data.status === -1) dispatch(createFatalError(data.error_details));
            if (!data) dispatch(createFatalError());
        })
        .catch((res = {}) => {
            sendOpenSearchLogs({
                event_group: "cashier_get_gateways",
                error_data: res
            })

            const { response = {} } = res;
            const { data = {} } = response;

            if (data.status === 0) return cashierGatewayValidator(data, dispatch);
            if (data.status === 2) dispatch(createResultOfPay(data));
            if (data.status === -1) dispatch(createFatalError(data.error_details));
            if (!data.status) dispatch(createFatalError());
        });
};

export const fetchProcessingGateways = (data) => (dispatch, getState) => {
    return new Promise((resolve) => {
        return getAvailableGateways({...data})
            .then((res) => {
                const { data = {} } = res;
                const { status } = data;

                sendOpenSearchLogs({
                    event_name: "get_avaible_gateways",
                    event_group: "cashier_events",
                    event_data: {
                        gateway_list: data.available_gateways
                    }
                });

                if (status === 0) {
                    dispatch(updateProcessingGateways(data));
                } else {
                    if (status === 2) dispatch(createResultOfPay(data));
                    if (status === -1) dispatch(createFatalError(data.error_details));
                }
                resolve(data);
            })
            .catch((res = {}) => {
                sendOpenSearchLogs({
                    event_group: "cashier_get_available_gateways",
                    error_data: res
                })

                const { response = {} } = res;
                const { data = {}, data: { status } = {} } = response;

                if (status === 0) {
                    dispatch(updateProcessingGateways(data));
                } else {
                    if (status === 2) dispatch(createResultOfPay(data));
                    if (status === -1) dispatch(createFatalError(data.error_details));
                    if (!status) {
                        postErrors({error: {stack: 'getAvailableGateways catch', message: JSON.stringify(response)}});
                        dispatch(createFatalError());
                    }
                }
                resolve(data);
            });
    });
}

export const handleResultResponse = (data, dispatch) => {
    const {status} = data;
    switch(status) {
        case 0:
            dispatch(createResultOfPay(data));
            break;
        case 2:
            dispatch(createResultOfPay(data));
            break;
        case -1:
            dispatch(createFatalError(data.error_details));
            break;
        case -3:
            dispatch(createValidationPayError(data.error_details));
            break;
        default:
            dispatch(createFatalError());
    }
}

const delayPayWithGateway = () => {
    return new Promise(resolve => {
        const interval = setInterval(() => {
            getUser(1).then(({data}) => {
                const {processing_in_progress} = data;
                if(!processing_in_progress) {
                    clearInterval(interval);
                    resolve({data})
                }
            })
        }, 10000)
    })
}

const updateRecoverySettingsAfterPayment = ({ dispatch, recoveryOptions }) => {
    const updatedRecoverySettings = {
        ...(recoveryOptions.ob_decline_recovery && {
            ob_decline_recovery: 0
        }),
        ...(recoveryOptions.retry_with_apm && {
            retry_with_apm: 0
        }),
    };

    RecoverySettingsModel.updateRecoverySettings({
        dispatch,
        recoverySettings: updatedRecoverySettings
    })
}

export const payWithGateway = (data, isQuickDeposit = false) => (dispatch, getState) => {
    const {
        currentGateway: {option_id, option_type}, gatewaysList: {is_encrypted_input_names = 0},
        virtualSettings, profileInfo: {remove_customer_data}
    } = getState();

    const recoverySettings = RecoverySettingsModel.getRecoverySettings();

    const apm_decline_recovery = recoverySettings.retry_with_apm;
    const { ob_decline_recovery } = recoverySettings;

    const params = {
        option_id,
        option_type,
        is_encrypted_input_names,
        remove_customer_data, ...virtualSettings, ...data
    };

    //tell the BE that this transaction is quick deposit
    if (isQuickDeposit) {
        params['is_quick_deposit'] = 1
    }

    const recoveryOptions = {
        ob_decline_recovery,
        apm_decline_recovery
    };

    return new Promise((resolve) => {
        return postPayGateway({ ...params, ...(apm_decline_recovery && { apm_decline_recovery }) })
            .then(({data} = {}) => {
                const {status, processing_in_progress} = data;

                if (ob_decline_recovery || apm_decline_recovery) {
                    updateRecoverySettingsAfterPayment({ dispatch, recoveryOptions });
                }

                if (status === 0) {
                    const { transaction } = data;
                    triggerAdvanceCashierEvent({
                        event_type: "transaction_attempted",
                        transaction: {
                            amount: transaction?.amount,
                            currency: transaction?.currency,
                            charge_amount: transaction?.charge_amount,
                            charge_currency: transaction?.charge_currency,
                            payment_method: transaction?.payment_method,
                            payment_processor: transaction?.payment_processor,
                            trace_id: transaction?.trace_id,
                            card_number: transaction?.card_number,
                        }
                    });
                }

                if ([0, 2].includes(status) && processing_in_progress) {
                    return delayPayWithGateway().then(({data}) => {
                        handleResultResponse(data, dispatch);
                        resolve(data);
                    })
                }
                handleResultResponse(data, dispatch);

                sendOpenSearchLogs({
                    event_group: "cashier_events",
                    event_name: "cashier_submit",
                    event_data: {
                        option_id: params.option_id,
                        option_type: params.option_type,
                        is_encrypted_input_names: params.is_encrypted_input_names,
                        remove_customer_data: params.remove_customer_data,
                        override_limits: params.override_limits,
                        payment_information: data.transaction,
                        device_data: getDeviceData(),
                    },
                    event_context: {
                        response_code: data.status,
                    }
                });

                resolve(data);
            })
            .catch((res = {}) => {
                sendOpenSearchLogs({
                    event_group: "cashier_post_pay_gateway",
                    error_data: res
                });

                if (ob_decline_recovery || apm_decline_recovery) {
                    updateRecoverySettingsAfterPayment({ dispatch, recoveryOptions });
                }

                const { response = {} } = res;
                const { data = {}, data: {status, processing_in_progress } = {}} = response;

                if([0,2].includes(status) && processing_in_progress) {
                    return delayPayWithGateway().then(({data}) => {
                        handleResultResponse(data, dispatch);
                        resolve(data);
                    })
                }
                handleResultResponse(data, dispatch);
                resolve(data);
            });
    });
};

export const payWithPartiallyApproved = (data) => (dispatch) => {
    return new Promise((resolve) => {
        return partialApprovedResult(data)
            .then(({data} = {}) => {
                handleResultResponse(data, dispatch);
                resolve(data);
            })
            .catch((res = {}) => {
                sendOpenSearchLogs({
                    event_group: "cashier_pay_with_partially_approved",
                    error_data: res
                })

                const { response = {} } = res;
                const { data = {} } = response;

                handleResultResponse(data, dispatch);
                resolve(data);
            });
    });
};

export const payWithManageRetryRequest = (data) => (dispatch, getState) => {
    const {payData} = getState();
    const retryData = {...data, ...payData};
    return new Promise((resolve) => {
        return manageRetryRequest(retryData)
            .then(({data} = {}) => {
                handleResultResponse(data, dispatch);
                resolve(data);
            })
            .catch((res = {}) => {
                sendOpenSearchLogs({
                    event_group: "cashier_pay_with_manage_retry_request",
                    error_data: res
                });

                const { response = {} } = res;
                const { data = {} } = response;
                handleResultResponse(data, dispatch);
                resolve(data);
            });
    });
};

export const getCustomerData = (callback = 0) => (dispatch) => {
    return new Promise((resolve) => {
        return getUser(callback)
            .then((res ={}) => {
                const { data } = res;

                if(callback && data.redirect_url) data.redirect_url = null;
                if (data.status === 0) {
                    if (window.cashierUpdateCustomer)
                        try {
                            window.cashierUpdateCustomer(data.customer);
                        } catch(e) {
                            sendOpenSearchLogs({
                                event_group: "cashier_update_customer",
                                error_data: {
                                    error: {
                                        message: 'getUser error',
                                        stack: e
                                    }
                                }
                            });
                        }
                    dispatch(changeProfile(data));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -3)
                    dispatch(createValidationProfileError(data.error_details));
                if (data.status === -1) dispatch(createFatalError(data.error_details));
                if (data.status === -2) dispatch(changeProfile(data));

                sendOpenSearchLogs({
                    event_group: "cashier_initialization",
                    event_name: "get_customer_data",
                    event_data: data,
                    event_context: {
                        http_status_code: res.status,
                        response_code: data.status,
                        response_message: res.statusText
                    }
                });

                resolve(data);
            })
            .catch((res = {}) => {
                sendOpenSearchLogs({
                    event_group: "cashier_get_user",
                    error_data: res
                });

                const { response = {} } = res;
                const { data = {} } = response;
                if (data.status === 0) {
                    if (window.cashierUpdateCustomer)
                        try {
                            window.cashierUpdateCustomer(data.customer);
                        } catch {
                        }
                    dispatch(changeProfile(data));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -3)
                    dispatch(createValidationProfileError(data.error_details));
                if (data.status === -1) dispatch(createFatalError(data.error_details));
                if (data.status === -2) dispatch(changeProfile(data));
                if (!data.status) dispatch(createFatalError());
                resolve(data);
            });
    });
};

export const saveDataInfo = (amount) => (dispatch) => {
    return new Promise((resolve) => {
        saveData({...amount})
            .then(({data}) => {
                if (data.status === -3)
                    dispatch(
                        createValidationPayError(data?.error_details?.payment_information)
                    );
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === 0) resolve(data);
                resolve(data);
            })
            .catch((res = {}) => {
                sendOpenSearchLogs({
                    event_group: "cashier_save_data_info",
                    error_data: res
                });

                const { response = {} } = res;
                const { data = {} } = response;
                if (data.status === -3)
                    dispatch(
                        createValidationPayError(data?.error_details?.payment_information)
                    );
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === 0) resolve(data);
                if (!data.status) dispatch(createFatalError());
                resolve(data);
            });
    });
};

export const setLimitSubmit = (payload) => {
    return {
        type: "SET_LIMIT_SUBMIT",
        payload,
    };
};

export const setLimitError = (payload) => {
    return {
        type: "SET_LIMIT_ERROR",
        payload,
    };
};

