export const initTranslations = {
    ar: { initialLoadingText: "جاري التحميل يرجى الانتظار" },
    bg: { initialLoadingText: "Зареждане, моля изчакайте" },
    cs: { initialLoadingText: "Načítání prosím čekejte" },
    da: { initialLoadingText: "Indlæser vent venligst" },
    de: { initialLoadingText: "Laden, bitte warten" },
    el: { initialLoadingText: "Φορτώνει... παρακαλώ περιμένετε" },
    en: { initialLoadingText: "Loading... Please Wait" },
    es: { initialLoadingText: "Cargando... por favor espere" },
    et: { initialLoadingText: "Laadimine... Palun oodake" },
    fi: { initialLoadingText: "Ladataan, odota" },
    fr: { initialLoadingText: "Chargement, veuillez patienter" },
    he: { initialLoadingText: "בטעינה אנא המתן" },
    hi: { initialLoadingText: "लोड हो रहा है कृपया प्रतीक्षा करें" },
    hu: { initialLoadingText: "Betöltés... kérem várjon" },
    hy: { initialLoadingText: "Բեռնվում է, խնդրում ենք սպասել" },
    id: { initialLoadingText: "Sedang memuat... Harap tunggu" },
    it: { initialLoadingText: "Attendere... il caricamento prego" },
    jp: { initialLoadingText: "読み込み中です。しばらくお待ちください" },
    ka: { initialLoadingText: "Იტვირთება გთხოვთ მოიცადოთ" },
    kh: { initialLoadingText: "កំពុងផ្ទុក... សូមរង់ចាំ" },
    ko: { initialLoadingText: "로딩 중 기다려주세요" },
    lt: { initialLoadingText: "Kraunasi... Prašome palaukti" },
    lv: { initialLoadingText: "Notiek ielāde... Lūdzu, uzgaidiet" },
    ms: { initialLoadingText: "Muat turun sila tunggu" },
    nl: { initialLoadingText: "Laden even geduld aub" },
    no: { initialLoadingText: "Laster Vennligst vent" },
    pl: { initialLoadingText: "Trwa ładowanie, proszę czekać" },
    pt: { initialLoadingText: "Carregando, por favor espere" },
    ru: { initialLoadingText: "Загрузка, пожалуйста подождите" },
    sk: { initialLoadingText: "Načitáva sa, prosím čakajte" },
    sl: { initialLoadingText: "Nalagam. Prosimo počakajte" },
    sq: { initialLoadingText: "Duke u ngarkuar, ju lutem prisni" },
    sr: { initialLoadingText: "Учитавање сачекајте" },
    sv: { initialLoadingText: "Laddar... Var god vänta" },
    ta: { initialLoadingText: "பதிவேறுகிறது, காத்திருக்கவும்" },
    th: { initialLoadingText: "กำลังโหลด... กรุณารอสักครู่" },
    tl: { initialLoadingText: "Naglo-load... Mangyaring maghintay" },
    tr: { initialLoadingText: "Yükleniyor.. Lütfen bekleyiniz" },
    uk: { initialLoadingText: "Завантаження, зачекайте будь-ласка" },
    vi: { initialLoadingText: "Tải vui lòng đợi" },
    zh_CHS: { initialLoadingText: "加载请稍候" },
    zh_CHT: { initialLoadingText: "加载请稍候" }
};
