import {Component} from 'react'
import {redirectWindowDom} from "../variables/redirect-window-dom";
import InternalizationService from "./internalization";

const {host} = window.location;
const {langBack} = new InternalizationService();

export default class RedirectWindow extends Component {
    
    redirectWindow = undefined;
    
    openRedirectWindow = ({url, target = "_blank", features = {}, cashier_theme}) => {
        this.redirectWindow = window.open(url, target, this.getParamsForPopup(features));
        
        const status = this.getStatusRedirectWindow()
        if (url === '' && status === "open") {
            this.redirectWindow.document.write(redirectWindowDom(langBack, cashier_theme));
        }
        return status;
    }
    
    closeRedirectWindow = () => {
        if (this.getStatusRedirectWindow() === "open") {
            this.redirectWindow.close()
        }
    }
    
    replaceWindow = (url) => {
        if (this.getStatusRedirectWindow() === "open") {
            this.redirectWindow.location.replace(url)
        }
    }
    
    isWindowSameDomain = () => {
        try {
            return this.redirectWindow.history.state !== null && this.redirectWindow.location.host === host
        } catch(e) {
            return false
        }
    }


    focusRedirectWindow = () => {
        if (this.getStatusRedirectWindow() === "open") {
            this.redirectWindow.focus()
        }
    }
    
    getStatusRedirectWindow = () => {
        switch (true) {
            case this.redirectWindow === undefined:
                return "not-exist";
            case this.redirectWindow === null:
                return "blocked";
            case this.redirectWindow?.closed:
                return "closed";
            case (typeof this.redirectWindow === "object"):
                return "open";
            default:
                return "indefinite";
        }
    }
    
    postMessageToWindow = (data) => {
        if (this.isWindowSameDomain()) {
            this.redirectWindow.postMessage({
                event_type: "message",
                ...data
            }, origin)
        }
    }
    
    getParamsForPopup = (features) => {
        let {
            window_size_width: width, window_size_height: height, payment_method_type: type
        } = features;
        
        if (!width) width = type === 'cc' ? 500 : 600;
        if (!height) height = type === 'cc' ? 600 : 800;
        
        let left = Number((window.screen.width / 2) - (+width / 2));
        let top = Number((window.screen.height / 2) - (+height / 2));
        
        return `width=${width}, height=${height}, top=${top}, left=${left}`
    }
    
}
