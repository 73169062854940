import {classicConfig} from "../../../themes-config/classic-config";
import {novaConfig} from "../../../themes-config/nova-config";
import {bassicConfig} from "../../../themes-config/basic-config";
import {defaultConfig} from "../../../themes-config/default-config";
import {darkConfig} from "../../../themes-config/dark-config";
import {supremeConfig} from "../../../themes-config/supreme-config";
import {powerSlideConfig} from "../../../themes-config/power-slide-config";
import {panoramic2_0Config} from "../../../themes-config/panoramic-2-0-theme";

import "./basic-theme/main.sass"
import "./classsic-theme/classic-styles/main.sass"
import "./nova-theme/avatrade-styles/main.sass"
import "./default-theme/default-styles/main.sass"
import "./dark-theme/dark-styles/main.sass"
import "./supreme-theme/supreme-styles/main.sass"



// Panoramic: default-theme
// Mosaic: supreme-theme
// Dark: dark-theme
// Tiles: nova-theme
// Table: classic
// PowerSlide: power-slide
// Panoramic 2.0: panoramic-2-0-theme

export const getTemplates = (key) => {
    //don't change this file if you want to change theme for test on localhost server. Change TEST-DATA file
    switch(key){
        case "classic": return classicConfig;
        case "nova-theme": return novaConfig;
        case "basic-theme": return bassicConfig;
        case "dark-theme": return darkConfig;
        case "default-theme": return defaultConfig;
        case "supreme-theme": return supremeConfig;
        case "power-slide": return powerSlideConfig;
        case "panoramic-2-0-theme": return panoramic2_0Config;
        default: return defaultConfig;
    }
};
