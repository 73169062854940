import React, {lazy, Suspense, useEffect, useMemo} from 'react'
import compose from "./utils/helper-functions/compose";
import {withTemplate} from "shared-components/hoc-components/hoc-context/with-template";
import {isPanoramicTheme} from "@pano-project/variables/is-panoramic-2-0-theme";
import Loader from "./shared-components/helper-components/loader/loader";
import {useSelector} from "react-redux";
import {applyFontFamilyFromTheme} from "./utils/fonts-functions/fonts-functions";

const CashierProject = lazy(() => import('@cashier-project/index.js'));
const NovaProject = lazy(() => import('@nova-project/index.js'));
const PanoramicProject = lazy(() => import('@pano-project/index.js'));

const App = (props) => {
    const {theme} = props;

    const {customer} = useSelector((state) => state.profileInfo);
    const initTheme = useSelector((state) => state.initTheme);
    const fontFromThemeSetting = useMemo(() => initTheme?.theme_editor_settings?.customization_settings?.font,
        [initTheme?.theme_editor_settings?.customization_settings?.font]);

    if (customer) {
        [customer.variable1, customer.variable2, customer.variable3].forEach((variable, index) => {
            if (variable && variable.length)
                document.body.parentElement.setAttribute(`data-variable${index + 1}`, variable)
        })
    }

    useEffect(() => {
        if (initTheme?.cashier_theme && initTheme.cashier_theme === "panoramic-2-0-theme" && fontFromThemeSetting) {
            applyFontFamilyFromTheme(fontFromThemeSetting);
        }
    }, [fontFromThemeSetting]);

    useEffect(() => {
        applyFontFamilyFromTheme("Muli");
    }, []);

    if(isPanoramicTheme) return (
        <Suspense fallback={<Loader />}>
            <PanoramicProject/>
        </Suspense>
    )

    switch (theme) {
        case "power-slide":
            return (
                <Suspense fallback={<Loader />}>
                    <NovaProject/>
                </Suspense>
            )
        default:
            return (
                <Suspense fallback={<Loader />}>
                    <CashierProject/>
                </Suspense>
            )
    }
}

export default compose(
    withTemplate
)(App)
