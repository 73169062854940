import {Component} from 'react'
import {getTemplates} from "../@cashier-project/assets/styles";
import {isProd} from "../variables/app-variables";
import {getInitTranslation} from "../utils/init-localizations";

let getDictionary = null;

if (!isProd) {
    getDictionary = require('../mock-data/mock-localization').getDictionary;
}

export default class InternalizationService extends Component {
    lang = (key, data={}, deleteIfEmpty=false) => {
        let res = dictionary[key];
        let arr = [];
        let index  = 0;
        let protection = 0;

        if(!res) return "";
        if(!Object.keys(data).length) return res;

        while(index !== -1 && protection <= 4){
            index = res.indexOf("{{", index);
            protection++;
            if(index !== -1){
                let from = res.indexOf("{{", index);
                let to = res.indexOf("}}", index);
                arr.push(res.slice(from+2, to));
                index = to;
            }
        }
        if(arr.length){
            arr.forEach(el => {
                if(!data[el] && deleteIfEmpty) return;
                if(!data[el]) data[el] = "";
                res = res.replace(`{{${el}}}`, data[el])
            })
        }
        return res
    };

    langBack = (key, isLowercase=false, useMockDictionary = false) => {
        if(!key) return key;
        let res;
        if(useMockDictionary) {
            const userLocale = navigator.languages && navigator.languages.length
                    ? navigator.languages[0]
                    : navigator.language;

            const currentDictionary = getDictionary ? getDictionary(userLocale) : dictionary;
            const translation = currentDictionary ? currentDictionary[key] : null;
            res = translation || key
        } else {
            res = dictionary[key];
        }
        if(!res && isLowercase){
            res = dictionary[key.toLowerCase()];
        }
        return res ? res : key
    };

    initDictionary = (key, default_translation, translation, dictionary_js) => {
        if(!translation || typeof translation !== "object") translation = {}
        dictionary = {...dictionary, ...default_translation, ...translation,
            ...this.checkCallbackTranslations(key, dictionary_js)};
    };
    checkCallbackTranslations = (key, dictionary={}) => {
        if(!dictionary || !dictionary[key] || typeof dictionary[key] !== "object" ) return {};
        return dictionary[key];
    }
    initCustomStyles = styles => customStyles = styles;
    initTemplate = (cashier_theme) => template = getTemplates(cashier_theme);

    getTemplate = () => template;
    getCustomStyles = () => customStyles;

    initLocalTranslations () {
        const userLocale = navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;

        const initTranslation = getInitTranslation(userLocale);
        dictionary = {...dictionary, ...initTranslation};
    }
}

let customStyles = false;
let template = {};
let dictionary = {};



