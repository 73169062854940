import {initTranslations} from "../variables/local-translations";

export const getInitTranslation = (key) => {
    let translation;
    if(key === "ar-SA" || key === "ar") translation = initTranslations.ar;
    else if(key === "de-DE" || key === "de") translation = initTranslations.de;
    else if(key === "el-GR" || key === "el") translation = initTranslations.el;
    else if(key === "en-GB" || key === "en") translation = initTranslations.en;
    else if(key === "es-ES" || key === "es-SV" || key === "es") translation = initTranslations.es;
    else if(key === "fi-FI" || key === "fi") translation = initTranslations.fi;
    else if(key === "fr-FR" || key === "fr") translation = initTranslations.fr;
    else if(key === "he-IL" || key === "he") translation = initTranslations.he;
    else if(key === "id-ID" || key === "id") translation = initTranslations.id;
    else if(key === "it-IT" || key === "it") translation = initTranslations.it;
    else if(key === "ja-JP" || key === "ja") translation = initTranslations.ja;
    else if(key === "nl-NL" || key === "nl") translation = initTranslations.nl;
    else if(key === "pl-PL" || key === "pl") translation = initTranslations.pl;
    else if(key === "pt-PT" || key === "pt") translation = initTranslations.pt;
    else if(key === "ru-RU" || key === "ru") translation = initTranslations.ru;
    else if(key === "sv-SE" || key === "sv") translation = initTranslations.sv;
    else if(key === "th-TH" || key === "th") translation = initTranslations.th;
    else if(key === "tr-TR" || key === "tr") translation = initTranslations.tr;
    else if(key === "vi-VN" || key === "vi") translation = initTranslations.vi;
    else if(key === "zh-CN" || key === "zh") translation = initTranslations.zh_CHS;
    else if(key === "zh-TW") translation = initTranslations.zh_CHT;
    else if(key === "ms-MY" || key === "ms") translation = initTranslations.ms;
    else if(key === "hi-IN" || key === "hi") translation = initTranslations.hi;
    else if(key === "cs-CZ" || key === "cs") translation = initTranslations.cs;
    else if(key === "tl-PH" || key === "en-PH" || key === "tl") translation = initTranslations.tl;
    else if(key === "hu-HU" || key === "hu") translation = initTranslations.hu;
    else if(key === "sk-SK" || key === "sk") translation = initTranslations.sk;
    else if(key === "ko-KR" || key === "ko") translation = initTranslations.ko;
    else if(key === "lt-LT" || key === "lt") translation = initTranslations.lt;
    else if(key === "kh-KH" || key === "kh") translation = initTranslations.kh;
    else if(key === "bg-BG" || key === "bg") translation = initTranslations.bg;
    else if(key === "hy-AM" || key === "hy") translation = initTranslations.hy;
    else if(key === "da-DK" || key === "da") translation = initTranslations.da;
    else if(key === "et-EE" || key === "et") translation = initTranslations.et;
    else if(key === "ka-GE" || key === "ka") translation = initTranslations.ka;
    else if(key === "lv-LV" || key === "lv") translation = initTranslations.lv;
    else if(key === "sl-SL" || key === "sl") translation = initTranslations.sl;
    else if(key === "nb-NO" || key === "no-NO" || key === "no") translation = initTranslations.no;
    else if(key === "sr-SP" || key === "sr") translation = initTranslations.sr;
    else if(key === "ta-IN" || key === "ta") translation = initTranslations.ta;
    else if(key === "uk-UA" || key === "uk") translation = initTranslations.uk;
    else if(key === "sq-AL" || key === "sq") translation = initTranslations.sq;
    else translation = initTranslations.en;

    return translation;
}
