import { sendOpenSearchLogs } from "utils/helper-functions/send-open-search-logs.ts";

// check if value of object prop is empty
const isEmpty = (val) => {
    return val === undefined || val === null ||
        (typeof val === 'string' && val.trim() === '')
        || (Array.isArray(val) && val.length === 0) ||
        (typeof val === 'object' && Object.keys(val).length === 0);
}

const assignValueToFilteredObj = (options = {}) => {
    const {
        obj = {},
        key = '',
        value,
    } = options;

    if(key) {
        try {
            obj[key] = value;
        } catch(err) {
            const errorText = `key: ${key} property of obj: ${obj} is invalid`;

            sendOpenSearchLogs({
                event_group: "cashier_assign_value_to_filtered_obj",
                error_data: {
                    error: {
                        message: errorText,
                        stack: err
                    }
                }
            });

            console.error(errorText, err);
        }
    } else {
        const errorText = `${key} property of ${obj} is empty or invalid`;

        console.error(errorText);
    }
}

// by default filtered empty values in nested object and can take callback
export const filterObject = (options = {}) => {
    const {
        obj = {},
        objName,
        callback,
        allowEmpty = false,
    } = options;

    try {
        return Object.entries(obj).reduce((acc, [key, val]) => {
            if(!isEmpty(val)) {
                if(typeof val === 'object') {
                    const filtered = filterObject({...options, obj: val});
                    if(allowEmpty || (!allowEmpty && !isEmpty(filtered))) {
                        const nestedObjOptions = {
                            obj: acc,
                            value: filtered,
                            key
                        }
                        if (typeof callback === 'function' && callback(val)) {
                            assignValueToFilteredObj(nestedObjOptions);
                        } else {
                            assignValueToFilteredObj(nestedObjOptions);
                        }
                    }
                } else {
                    const valueOptions = {
                        obj: acc,
                        value: val,
                        key
                    }
                    if (typeof callback === 'function' && callback(val)) {
                        assignValueToFilteredObj(valueOptions);
                    } else {
                        assignValueToFilteredObj(valueOptions);
                    }
                }
            }
            if(!allowEmpty && isEmpty(val)) {
                const errorText = `${key} property of ${objName ?? 'object'} is empty`;

                console.error(errorText);
            }

            return acc;
        }, {});
    } catch (err) {
        const errorText = `Cant filter ${objName ?? 'obj'}`;

        sendOpenSearchLogs({
            event_group: "cashier_filter_object",
            error_data: {
                error: {
                    message: errorText,
                    stack: err
                }
            }
        });

        console.error(errorText, err)
    }
}