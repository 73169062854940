import React, {useState, useEffect} from 'react'
import './loader.sass'
import {isChineseDomain} from "variables/is-chinese-domain";
import {getUrl} from "variables/host";
import {withI18N} from "../../hoc-components";
import compose from "utils/helper-functions/compose";
import Timer from "../timer";
import PaymentMethodSkeleton from "@pano-project/pages/processing-pages/current-method-page/payment-method-skeleton";
import { RecoverySettingsModel } from "utils/models/RecoverySettings.Model.ts";

const Loader = (props) => {
    const {
        fixed = false,
        index = false,
        text = false,
        showLoaderIcon = true,
        className = '',
        langBack,
        showTimer = false,
        isDynamicFields = false,
        skeletonLoader = false
    } = props;
    const [delayLoaderText, setDelayLoaderText] = useState(false)
    const loaderLink = `${isChineseDomain ?
        'https://alt-cdn.prx-pay.com/images/Cashier_cubes_preloader.gif' :
        getUrl('assetsLink')
    }`
    const { ob_decline_recovery, ob_additional_fields_filled } = RecoverySettingsModel.getRecoverySettings();
    
    useEffect(() => {
        const timeId = setTimeout(() => {
            setDelayLoaderText(true)
        }, 15000)
        
        return () => {
            clearTimeout(timeId)
        }
    }, [])
    
    if(skeletonLoader && !isDynamicFields) {
        return <PaymentMethodSkeleton/>
    }
    
    return (
        <div
            className={`loader-container ${fixed ? "fixed" : ""} ${index ? "index" : ""} ${delayLoaderText ? 'loader-delay' : ''} ${ob_decline_recovery && ob_additional_fields_filled && !isDynamicFields ? 'non-transparent' : ''} ${className ?? ''}`}>
            <>
                {text && <div className={'loader-text'}>{text}</div>}
                {showLoaderIcon && <div className="loader" style={{backgroundImage: `url(${loaderLink})`}}></div>}
                {delayLoaderText && <div className={'loader-delay-text'}>{langBack("delayLoaderText")}</div>}
                {showTimer && <Timer/>}
            </>
        
        </div>
    )
};

export default compose(withI18N)(Loader);