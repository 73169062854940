import Loader from "../helper-components/loader/loader";
import {compose} from "redux";
import React, {useEffect, useState} from 'react';
import {useLocation, withRouter} from "react-router";
import {getHashStringParams, getQueryStringParams} from "utils/helper-functions/get-url-vars";
import { saveLocation, tokenInit } from "state/actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {config} from 'config/config'
import {getTokenFromUrl} from "utils/helper-functions/getTokenFromUrl";
import {withPraxisService} from "../hoc-components";
import {withI18N} from "../hoc-components";
import {getUrl} from "variables/host";
import PanoErrorPage from "@pano-project/pages/error-page/error-page";
import ErrorPageContainer from "../container-components/error-page-container/error-page-container";
import {isPanoramicTheme} from "../../@pano-project/variables/is-panoramic-2-0-theme";
import { RecoverySettingsModel } from "utils/models/RecoverySettings.Model.ts";
import { getRandomString } from 'utils/helper-functions/get-random-string.ts';
import { createSessionId } from "state/actions";

const ReadTokenFromUrl = ({children, history, praxisService: {postComputeLogs}, langBack}) => {
    const [invalidToken, setToken] = useState(false);
    const dispatch = useDispatch()
    const {token} = useSelector(({token}) => ({
        token
    }), shallowEqual);
    const location = useLocation();

    useEffect(() => {
        const {hash, search} = location;
        const token = getTokenFromUrl();
        const queryParams = getQueryStringParams(search);
        const hashParams = getHashStringParams(hash);
        
        if (!token) {
            postComputeLogs({error_details: "Can't read token from url", url: window.location.href})
            return setToken(!invalidToken);
        }
        initApp(token, {...queryParams, ...hashParams});
    }, []);
    
    const handleRecoverySettingsString = (recoverySettingsString, token) => {
        const decodedString = atob(recoverySettingsString);
        const recoverySettingsObj = JSON.parse(decodedString);
        const currentTokenTrimmed = `${token.substring(0, 5)}${token.substring(token.length - 5)}`
        
        if (currentTokenTrimmed !== recoverySettingsObj?.trimmed_token) {
            setToken(!invalidToken);
        }
        
        RecoverySettingsModel.updateRecoverySettings({ dispatch, recoverySettings: recoverySettingsObj })
    }
    
    const initApp = (token, url) => {
        window.token = token;
        if (config.pciLink) addScript(getUrl('pciLink'));
        const recoverySettingFromUrl = url?.recoverySettings;
        recoverySettingFromUrl && handleRecoverySettingsString(recoverySettingFromUrl, token)
        dispatch(tokenInit(token));
        dispatch(saveLocation({ 
            ...window.location, ...url, 
            ...(recoverySettingFromUrl && {
                recoverySettingsObject: RecoverySettingsModel.parseRecoverySettingsString(recoverySettingFromUrl)
            }) 
        }));

        const existingSessionId = url?.sessionId;
        if (existingSessionId) {
            dispatch(createSessionId(existingSessionId));
        } else {
            const newSessionId = `${new Date().valueOf()}${getRandomString(3)}`;
            dispatch(createSessionId(newSessionId));
        }        

        history.push(`/${token}/`);
    }
    
    const addScript = (src) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = false;
        document.head.appendChild(script);
    }
    
    if (invalidToken) return (
        <ErrorPageContainer errorType={2} back={false}>
            {isPanoramicTheme && <PanoErrorPage wrapper={true}/>}
        </ErrorPageContainer>
    );
    
    if (!token) return <Loader text={langBack('initialLoadingText', false, true)} showLoaderIcon={false} />;
    
    return children
    
};

export default compose(
    withRouter, withPraxisService(), withI18N
)(ReadTokenFromUrl);

