import { updateProfile } from "./update-profile";
import { updateGateways } from "./update-gateways";
import { createResultOfPay } from "./create-result-of-pay";
import { createError } from "./create-validation-error";
import { createToken } from "./create-token";
import { changePayData } from "./change-pay-data";
import { createCurrentGateway } from "./create-current-gateway";
import { saveLocation } from "./save-location";
import { createFocus } from "./create-focus";
import { virtualSettings } from "./virtual-settings";
import { changePayInfo } from "./change-pay-info";
import { initTheme } from "./init-theme";
import { createMethod } from "./create-method";
import { changeBaseAmount } from "./change-amount";
import { pendingTransactions } from "./pending-transactions";
import { createGatewayInfo } from "./create-gateway-info";
import { limitSubmit } from "./limit-submit";
import { createAdrToken } from "./create-adr-token";
import { limitError } from "./limit-error";
import { updateProcessingGatewaysList } from "./update-processing-gateways";
import { createSessionId } from "./create-session-id";
import { setEnableSessionEvents } from "./set-enable-session-events";

const reducer = (state, action) => {
  return {
    token: createToken(state, action),
    adrToken: createAdrToken(state, action),
    initTheme: initTheme(state, action),
    location: saveLocation(state, action),
    gatewaysList: updateGateways(state, action),
    profileInfo: updateProfile(state, action),
    resultOfPay: createResultOfPay(state, action),
    processingGatewaysList: updateProcessingGatewaysList(state, action),
    errors: createError(state, action),
    payData: changePayData(state, action),
    payInfo: changePayInfo(state, action),
    currentGateway: createCurrentGateway(state, action),
    gatewayInfo: createGatewayInfo(state, action),
    focus: createFocus(state, action),
    method: createMethod(state, action),
    limitSubmit: limitSubmit(state, action),
    changedAmount: changeBaseAmount(state, action),
    virtualSettings: virtualSettings(state, action),
    pendingTransactions: pendingTransactions(state, action),
    limitError: limitError(state, action),
    sessionId: createSessionId(state, action),
    enableSessionEvents: setEnableSessionEvents(state, action),
  };
};
export default reducer;
