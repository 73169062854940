const createSessionId = (state, action) => {
    if (state === undefined) {
        return false;
    }

    switch (action.type) {
        case 'SESSION_ID_INIT':
            return action.payload;
        default:
            return state.sessionId
    }
}

export { createSessionId };
