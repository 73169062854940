const googleFontsLink = 'https://fonts.googleapis.com/css2?family=';
const fontWeights = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];

const italicFonts = [
  'Be Vietnam Pro', 'Bitter', 'Chakra Petch', 'Lato', 'Lora',
  'Montserrat', 'Noto Sans','Noto Serif', 'Nunito', 'Open Sans',
  'PT Sans', 'PT Serif', 'Raleway', 'Roboto', 'Titillium Web', 'Ubuntu Mono'
];

/**
 * Applies a given font family from the theme by dynamically adding the appropriate
 * Google Fonts stylesheet link to the document head.
 *
 * @param {string} fontFamily - The font family to be applied.
 */
export const applyFontFamilyFromTheme = (fontFamily: string) => {
  const formattedFont = fontFamily.replace(/ /g, '+');
  const wght = fontWeights.join(';');
  let link = document.createElement('link');
  link.rel = 'stylesheet';

  if (italicFonts.includes(fontFamily.replace('+', ' '))) {
    const italicWght = fontWeights.map(weight => `0,${weight}`)
      .concat(fontWeights.map(weight => `1,${weight}`))
      .join(';');

    link.href = `${googleFontsLink}${formattedFont}:ital,wght@${italicWght}&display=swap`;
    document.head.appendChild(link);
  } else {
    link.href = `${googleFontsLink}${formattedFont}:wght@${wght}&display=swap`;
    document.head.appendChild(link);
  }
}
