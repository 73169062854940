export const config = {
   configName: "config3",
   title: "Cashier",
   logo: null,
   assetsLink: "https://assets.{{domain}}/images/Cashier_cubes_preloader.gif",
   defaultDomain: "praxispay.com",
   defaultPciDomain: "praxisgate.com",
   api: "https://api-{{domain}}/api/",
   apiTheme: "https://compute.{{domain}}/hermes-assets/",
   origin: "https://ccport.praxispay.com",
   withdrawRequests: false,
   activityScript: true,
   apiServer: "https://atlas.praxispay.com/",
   pciLink: `https://cdn.{{domain}}/PraxisGate.js`,
   socketUrl: "wss://prometheus.{{domain}}/",
   publicKeyForEncrypt: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwLKqExdJL6gmSxUMvv2NUu366XjCJ+yHuvX4jBrm2S7SmFFlIoaf0U40aufKm2dlHvTvvjO+DJq9oyTBnPCd29vCUU8YHohv2yzKvtAKCZEbt8aA3cAaBI9dDjTk7yZclQJKTTava/bUQCQno1M2mYmV3uLg1KpEUY9hmGKylpObgoz+S1PeArh1PEvmjQlUb4/Vki/AJUCqkarIW8mjHMcmgXWOAHNU6+BublH8i7YA8xnOArjZRuofNJE75B/hGZ92AI5Xf56jFXNIpOoldmx0n3C5o2x7uXMl4uh5ghI7v+d0RH0tcwNnV8oWKjnBK/wJVe8NqAzFySgDV5RhxwIDAQAB",
   signatureSecretKey: "SvX6meGq9o",
   useConfigApiForMock: false,
   graphqlDomain: "https://graphql.{{domain}}/graphql",
   logsApi: "https://compute.{{domain}}"
};
